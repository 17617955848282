import { useQuery } from 'react-query';
import { client } from '../client';

export const useQueryWorks = () => {
  const getWorks = async () => {
    const data = await client.getEntries({
      content_type: 'portfolio',
    });
    return data
  }

  return useQuery({
    queryKey: 'works',
    queryFn: getWorks,
    cacheTime: 10000,
    staleTime: 0,
  })
}