import { css } from '@emotion/react';

export const footer = css({
  width: '100%',
  height: '6rem',
  backgroundColor: 'var(--footer-color)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& small': {
    fontSize: '1.2rem',
    color: 'var(--text-color)',
    opacity: 0.7
  }
});