/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  skillWrap,
  image,
} from './Skill.style';

const Skill = ({ skill }) => {
  return (
    <div css={skillWrap}>
      <img src={skill.fields.logo.fields.file.url} alt="skill logo" css={image} />
      <p>{skill.fields.language}</p>
    </div>
  );
};

export default Skill;
