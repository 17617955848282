import { css } from '@emotion/react';

export const head = css({
  height: '100vh',
  background: 'var(--head_background-color)',
  position: 'relative',
  '@media(max-width: 1024px)': {
    overflow: 'hidden',
  },
});

export const starImg = css({
  position: 'absolute',
  top: '8%',
  right: '38%',
  width: '90px',
  display: 'block',
  filter: 'drop-shadow(0 5px 25px #e5b900)',
  opacity: '0.9',
  '@media(max-width: 1024px)': {
    width: '80px',
    transform: 'scale(-1, 1)',
    top: '12%',
    right: '70%',
  },
  '@media(max-width: 768px)': {
    top: '14%',
  },
  '@media(max-width: 599px)': {
    width: '50px',
    top: '19%',
  },
});

export const birdImg = css(starImg, {
  top: '6%',
  right: '40%',
  width: '70px',
  filter: 'none',
  '@media(max-width: 1024px)': {
    transform: 'scale(-1, 1)',
    width: '70px',
    top: '14%',
  },
  '@media(max-width: 599px)': {
    width: '45px',
    right: '76%',
  },
});

export const tokyoImg = css({
  transform: 'scale(1,-1)',
  opacity: '0.5',
});

export const nameWrap = css({
  display: 'flex',
  position: 'absolute',
  color: 'rgba(255,255,255,0.7)',
  top: '22%',
  left: '50%',
  zIndex: 10,
  transform: 'translate(-50%,-22%)',
  '@media(max-width: 1024px)': {
    flexDirection: 'column',
    top: '16%',
  },
  '@media(max-width: 599px)': {
    top: '22%',
  }
});

export const nameWrapitem = css({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  fontSize: '9rem',
  '@media(max-width: 1024px)': {
    fontSize: '8rem',
  },
  '@media(max-width: 599px)': {
    fontSize: '4rem',
  },
  '&:first-of-type': {
    marginRight: '10rem',
    '@media(max-width: 1024px)': {
      marginRight: 0,
      marginBottom: '2rem',
    },
  },
  '& h1': {
    fontFamily: 'Krungthep',
    lineHeight: 1,
    cursor: 'pointer',
    marginRight: '5rem',
    userSelect: 'none',
    '@media(max-width: 1024px)': {
      marginRight: '4rem',
    },
    '@media(max-width: 599px)': {
      marginRight: '1.5rem',
    },
    '& img': {
      '@media(max-width: 1024px)': {},
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
});

export const titleImg = (isLighttheme) =>
  css({
    width: '94px',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 1024px)': {
      width: '8rem',
    },
    '@media(max-width: 599px)': {
      width: '4rem',
    },
    '& img': {
      filter: isLighttheme ? 'none' : 'drop-shadow(0 1px 30px #e5b900)',
    },
  });

export const name1 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
  });

export const name2 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
  });

export const name3 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
    webkitAnimation: clicked ? 'blink 3s infinite alternate' : 'none',
    animation: clicked ? 'blink 3s infinite alternate' : 'none',
  });

export const name4 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
    webkitAnimation: clicked ? 'blink 3s infinite alternate' : 'none',
    animation: clicked ? 'blink 3s infinite alternate' : 'none',
  });

export const name5 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
  });

export const name6 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
  });

export const name7 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
    webkitAnimation: clicked ? 'blink 3s infinite alternate' : 'none',
    animation: clicked ? 'blink 3s infinite alternate' : 'none',
  });

export const name8 = (clicked) =>
  css({
    color: clicked ? '#fff' : '',
    textShadow: clicked
      ? '0 1px 30px #e5b900, 0 0 12px #fff, 2px 5px 60px #997c00'
      : 'none',
  });

export const position = css({
  fontFamily: 'Krungthep',
  color: '#fff',
  position: 'absolute',
  fontSize: '3.8rem',
  top: '65%',
  left: '50%',
  width: '100%',
  textAlign: 'center',
  transform: 'translate(-50%,-65%)',
  zIndex: 10,
  '@media(max-width: 1024px)': {
    marginTop: '2rem',
    fontSize: '3.2rem',
  },
  '@media(max-width: 599px)': {
    marginTop: '3rem',
    fontSize: '2.5rem',
  },
});

export const imgWrap = css({
  zIndex: 10,
  position: 'absolute',
  top: '58%',
  transform: 'translateY(-58%)',
  width: '100%',
  '@media(max-width: 1024px)': {
    transform: 'translateY(-58%) scale(1.1)',
  },
  '@media(max-width: 768px)': {
    transform: 'translateY(-58%) scale(1.3)',
  },
  '@media(max-width: 599px)': {
    transform: 'translateY(-58%) scale(2.8)',
  },
});