import gsap from 'gsap';

export const circleSpreadAnimation = (target) => {
  const TL = gsap.timeline();
  TL.to(target.children[1], {
    visibility: 'visible',
    transform: 'translate(-50%, -50%) scale(30)',
    duration: 1.2,
    delay: 4.5
  })
  .to(target, {
    display: 'none',
    opacity: 0
  })
}