import { css } from '@emotion/react';

export const title = css({
  fontFamily: 'Krungthep',
  color: 'var(--primary-color)',
  fontSize: '5.5rem',
  lineHeight: 1,
  '& span': {
    fontSize: '3.8rem',
  },
  '@media(max-width: 1024px)': {
    fontSize: '4.5rem',
    '& span': {
      fontSize: '2.8rem',
    },
  },
});
