/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { animationWrap } from './Wave.style';

const Wave = () => {
  return (
    <div css={animationWrap}>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
        maxwidth="100%"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="0"
            fill="var(--head_wave1)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="3"
            fill="var(--head_wave2)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="5"
            fill="var(--head_wave3)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="7"
            fill="var(--background-color)"
          />
        </g>
      </svg>
    </div>
  )
};

export default Wave;