/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { HashLink } from 'react-router-hash-link';
import {
  navBar,
  navList,
  line
} from './Navbar.style';

const Navbar = ({ navRef, navListRef }) => {
  return (
    <nav css={navBar} ref={navRef}>
      <ul css={navList} ref={navListRef}>
        <HashLink smooth to="/#top">
          <li>TOP</li>
        </HashLink>
        <HashLink smooth to="/#my-work">
          <li>MY WORK</li>
        </HashLink>
        <HashLink smooth to="/#about-me">
          <li>ABOUT ME</li>
        </HashLink>
      </ul>
      <span css={line} />
    </nav>
  );
};

export default Navbar;
