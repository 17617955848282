import { useQuery } from 'react-query';
import { client } from '../client';

export const useQueryProfile = () => {
  const getProfile = async () => {
    const data = await client.getEntries({
      content_type: 'profile',
    })

    return data;
  }


  return useQuery({
    queryKey: 'profile',
    queryFn: getProfile,
    cacheTime: 10000,
    staleTime: 0,
  })
}