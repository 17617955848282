import { css } from '@emotion/react';

export const modalWrap = css({
  backgroundColor: 'var(--background-color)',
  opacity: 0.7,
  visibility: 'hidden',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 9999,
  transform: 'translateY(40px)'
})

export const workContents = css({
  height: '95%',
  maxWidth: '100%',
  margin: '0 auto',
  overflowY: 'scroll',
  position: 'relative',
  '@media(max-width: 1024px)': {
    maxWidth: '100%',
  },
});

export const workContentsWrap = css({
  maxWidth: '1200px',
  height: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '14rem',
  padding: '10rem 1.5rem',
  '@media(max-width: 1024px)': {
    padding: '6rem 2rem',
    gap: '10rem',
  },
  '@media(max-width: 599px)': {
    padding: '6rem 1.6rem',
    gap: '8rem',
  },
})

export const workTopImage = css({
  width: '100%',
  height: '250px',
  objectFit: 'cover',
})

export const workTitle = css({
  textAlign: 'center',
  fontSize: '10rem',
  '@media(max-width: 1024px)': {
    fontSize: '8rem',
  },
  '@media(max-width: 599px)': {
    fontSize: '5rem',
  },
});

export const workDescWrap = css({
  display: 'flex',
  '@media(max-width: 1024px)': {
    flexDirection: 'column',
  },
  '& p': {
    lineHeight: 1.4,
    letterSpacing: '0.08rem'
  }
});

export const workDescTitle = css({
  color: 'var(--work-title-color)',
  fontSize: '1.4rem',
  marginBottom: '1.3rem',
  display: 'block'
})

export const workDescLeft = css({
  width: '25%',
  marginRight: '10%',
  '@media(max-width: 1024px)': {
    width: '100%',
    marginRight: '0',
  },
});

export const workDescRole = css({
  marginBottom: '4rem',
});

export const workDescConcept = css(workDescLeft, {
  width: '65%',
  marginRight: 0,
  '@media(max-width: 1024px)': {
    width: '100%',
    marginTop: '3rem'
  }
});

export const linkBtnWrap = css({
  display: 'flex',
  justifyContent: 'center',
  gap: '3rem',
  '@media(max-width: 599px)': {
    flexDirection: 'column',
  },
  '& span': {
    marginRight: '1rem'
  },
  '& a': {
    color: 'var(--primary-color)',
    fontSize: '1.8rem',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'ease-in-out 0.3s',
    ":hover": {
      opacity: '0.7'
    },
    '&:first-of-type': {
      marginRight: '5rem',
      '@media(max-width: 599px)': {
        marginRight: 0,
        // marginBottom: '3rem',
      },
    },
  },
});

export const workDeskMultiplePcImgWrap = css({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '3rem',
  '@media(max-width: 599px)': {
    flexDirection: 'column',
    gap: '2rem',
  }
});

export const workDeskSinglePcImgWrap = css({
  width: '100%',
});

export const workDeskPcImage = css({
  marginBottom: '4rem',
  // boxShadow: '0 16px 30px 5px rgb(0 0 0 / 15%)',
  '&:last-of-type': {
    marginBottom: 0
  },
})

export const workDeskSpImgWrap = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '3rem',
})

export const workDeskSpImage = css({
  // boxShadow: '0 16px 30px 5px rgb(0 0 0 / 15%)',
})

export const closeBtn = css({
  position: 'absolute',
  right: '3rem',
  top: '2rem',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  padding: 0,
  appearance: 'none',
  height: '40px',
  width: '40px',
  '@media(max-width: 1024px)': {
    right: '2rem',
  },
  '@media(max-width: 599px)': {
    right: '1rem',
    top: '1rem',
  },
  '& div': {
    position: 'fixed',
    transform: 'translateY(-20px)'
  },
  '& span': {
    display: 'block',
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '100px',
    transition: 'all 0.6s',
    '&:hover': {
      opacity: 0.7
    },
    '&:before, &:after': {
      display: 'block',
      content: '""',
      height: '1px',
      width: '17px',
      background: '#ffffff'
    },
    '&:before': {
      transform: 'translate(12px, 20px) rotate(45deg)',
    },
    '&:after': {
      transform: 'translate(12px, 19px) rotate(-45deg)'
    }
  }
})