/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { footer } from './Footer.style';

const Footer = () => {
  return (
    <footer css={footer}>
      <small>©2022 MAI GOHARA</small>
    </footer>
  )
}

export default Footer;