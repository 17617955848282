import { css } from '@emotion/react';

export const skillWrap = css({
  display: 'flex',
  width: '30%',
  alignItems: 'center',
  marginBottom: '3rem',
  fontSize: '1.4rem',
  color: 'var(--text-color)',
  '@media(max-width: 599px)': {
    width: '48%',
    fontSize: '1.5rem',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
});

export const image = css({
  width: '15%',
  marginRight: '15px',
  '@media(max-width: 599px)': {
    width: '25%',
  }
});