/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import { styles } from './Global.style';
import Top from './pages/top/Top';

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Global styles={styles}/>
        <Router>
          <Routes>
            <Route path="/" element={<Top />} />
          </Routes>
        </Router>
    </QueryClientProvider>
  );
}

export default App;
