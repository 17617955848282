import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// gsap animation
export const followingAnimation = (target, trigger, el) => {
  let position;
  const mm = gsap.matchMedia();

  mm.add(
    {
      isDesktop: '(min-width: 1024px)',
      isTablet: '(max-width: 1023px)',
      isMobile: '(max-width: 767px)',
    },
    (context) => {
      const { isDesktop, isTablet, isMobile } = context.conditions;
      switch(el) {
        case 'name':
          position = {
            y: isMobile ? 180 : isTablet ? 300 : isDesktop ? 210 : 210
          };
          break;
        case 'wave':
          position = {
            y: isMobile ? -20 : isTablet ? -60 : isDesktop ? -100 : -100,
          };
          break;
        default:
          position = {};
      }

      gsap.to(target, {
        ...position,
        scrollTrigger: {
          trigger: trigger,
          start: 'bottom bottom',
          // markers: true,
          scrub: true
        }
      });
    }
  )
}

// Framer motion
export const nameVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: "easeInOut"
    }
  },
}

export const occupationVariants = {
  hidden: {
    opacity: 0,
    y: 10,
    x: "-50%"
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 1.2,
      ease: "easeInOut"
    },
    y: 0,
    x: "-50%"

  }
}