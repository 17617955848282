import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const fadeInAnimation = (target, type) => {
  let fadeIn;
  const mm = gsap.matchMedia();

  mm.add(
    {
      isDesktop: '(min-width: 1024px)',
      isTablet: '(max-width: 1023px)',
      isMobile: '(max-width: 767px)',
    },
    (context) => {
      const { isMobile } = context.conditions;

      switch (type) {
        case 'scrollFadeIn':
          fadeIn = {
            scrollTrigger: {
              trigger: target,
              start: 'top bottom-=150px',
              // markers: true,
            }
          }
          break;
        case 'scrollEachItemFadeIn':
          fadeIn = {
            stagger: {
              from: 'start',
              amount: 0.5,
            },
            scrollTrigger: {
              trigger: target,
              start: 'top bottom-=150px',
              // markers: true,
            }
          }
          break;
        default:
          fadeIn = {};
      }

      gsap.set(target, {
        autoAlpha: 0,
        y: 40,
      });

      if(type === 'scrollEachItemFadeIn' && isMobile) {
        (target).forEach((el) => {
          gsap.to(el, {
            autoAlpha: 1,
            duration: 1,
            y: 0,
            scrollTrigger: {
              trigger: el,
              start: 'top-=100px center',
              // markers: true,
            },
          });
        });
      } else {
        gsap.to(target, {
          autoAlpha: 1,
          duration: 1,
          y: 0,
          ...fadeIn,
        });
      }
    }
  )
}