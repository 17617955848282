import { css } from '@emotion/react';

export const content = css({
  display: 'flex',
  gap: 100,
  justifyContent: 'space-between',
  marginTop: '5rem',
  '@media(max-width: 768px)': {
    flexDirection: 'column'
  }
})

export const contentLeft = css({
  color: '#fff',
  width: '60%',
  zIndex: '1',
  '@media(max-width: 768px)': {
    width: '100%'
  }
});

export const contentRight = css({
  width: '40%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'left',
  position: 'relative',
  '& h2': {
    color: 'var(--primary-color)',
    paddingBottom: '4rem'
  },
  '@media(max-width: 768px)': {
    width: '100%',
  }
})

export const skillsWrap = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: '5rem',
});

export const description = css({
  color: 'var(--text-color)',
  lineHeight: 1.5,
  letterSpacing: '0.05rem'
});

export const descriptionName = css({
  fontFamily: 'Garamond',
  fontSize: '3rem',
  marginBottom: '0.5rem',
  display: 'block'
})

export const contactInfo = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 50,
  '& a': {
    color: 'var(--text-color)',
    display: 'flex',
    alignItems: 'center',
    transition: '0.3s',
    '&:hover': {
      color: 'var(--primary-color)',
    },
  }
});

export const contactInfoContent = {
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  letterSpacing: '0.1rem',
  '& span': {
    paddingRight: '1.2rem'
  }
}

export const contactInfoItem = {
  display: 'flex'
}

export const iconWrap = {
  display: 'flex',
  flexDirection: 'row',
  gap: 30,
  fontSize: '2.2rem'
}