/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRef, useState, useEffect } from 'react';
import Head from '../../components/top/Head/Head';
import WorkList from '../../components/top/WorkList/WorkList';
import About from '../../components/top/About/About';
import { contents } from './Top.style';
import Layout from '../../components/layout/Layout';
import Navbar from '../../components/layout/Navbar/Navbar';
import TopLoader from '../../components/layout/Loader/topLoader/TopLoader';
import { navbarAnimation } from '../../components/layout/Navbar/Navbar.animation';
import { useQueryWorks } from '../../hooks/useQueryWorks';
import { useQuerySkills } from '../../hooks/useQuerySkills';
import { useQueryProfile } from '../../hooks/useQueryProfile';

const Top = () => {
  const [pageLoad, setPageLoad] = useState(false)

  const { data: works, isLoading: isLoadingWorks, isFetching: isFetchingWorks } = useQueryWorks();
  const { data: skills, isLoading: isLoadingSkills, isFetching: isFetchingSkills } = useQuerySkills();
  const { data: profile, isLoading: isLoadingProfile, isFetching: isFetchingProfile } = useQueryProfile();

  const contentRef = useRef(null)
  const navRef = useRef(null);
  const navListRef = useRef(null)

  useEffect(() => {
    navbarAnimation(navRef.current, contentRef.current)
    // navbarTextAnimation(contentRef.current.childNodes, navListRef.current.childNodes)

    const onPageLoad = () => {
      setPageLoad(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  },[])

  return (
    <Layout>
      <Navbar navRef={navRef} navListRef={navListRef}/>
      <TopLoader pageLoad={pageLoad}/>
      <Head />
      <div css={contents} ref={contentRef}>
        <WorkList 
          works={works?.items} 
          isLoadingWorks={isLoadingWorks} 
          isFetchingWorks={isFetchingWorks}
        />
        <About 
          skills={skills?.items} 
          isLoadingSkills={isLoadingSkills} 
          isFetchingSkills={isFetchingSkills}
          profile={profile?.items} 
          isLoadingProfile={isLoadingProfile} 
          isFetchingProfile={isFetchingProfile}
        />
      </div>
    </Layout>
  );
};

export default Top;
