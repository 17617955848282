/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Lottie from 'react-lottie-player';
import squashDot from '../../../../assets/lottieAnimation/squashDot.json';
import { background } from './Loader.style';

const Loading = () => {
  return (
    <div css={background}>
      <Lottie
        speed={1}
        play={true}
        loop={true}
        animationData={squashDot}
        style={{ width: 250, height: 250 }}
      />
    </div>
  )
}

export default Loading;
