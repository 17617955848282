import gsap from 'gsap';

export const modalDisplayAnimation = (target) => {
  gsap.to(target, {
    y: 0,
    autoAlpha: 1
  })
}

export const modalHiddenAnimation = (target) => {
  gsap.to(target, {
    y: 40,
    autoAlpha: 0
  })
}