/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/react';
import { workItemWrap, workItemContent, imgWrap, textWrap, workName, workTechnologies, workTechnologiesDivider, linkWrap, projectName, projectRole  } from './WorkItem.style';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const WorkItem = ({ work, handleModalOpen, handleLinkClick }) => {
  return (
    <div css={workItemWrap}>
      <div 
        css={workItemContent} 
        onClick={() => handleModalOpen(work.sys.id)}
      >
        <div css={imgWrap}>
          <img src={work.fields.logo.fields.file.url} alt=""/>
        </div>
        <div css={textWrap}>
          <p css={workName}>{work.fields.projectName}</p>
          <p css={workTechnologies}>
            {work.fields.useTechnologies.map((technology, index) => (
              <Fragment key={index}>{technology}{index < work.fields.useTechnologies.length - 1 && <span css={workTechnologiesDivider}>/</span>}</Fragment>
            ))}
          </p>
          <div css={linkWrap}>
            {work.fields.github && (
              <button onClick={(e) => handleLinkClick(e, work.fields.github)}>
                <span>
                  <FontAwesomeIcon icon={faGithub} />
                </span>
              </button>
            )}
            {work.fields.appUrl && (
              <button onClick={(e) => handleLinkClick(e, work.fields.appUrl)}>
                <span>
                  <FontAwesomeIcon icon={faLink} />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      <p css={projectName}>{work.fields.projectName}</p>
      <p css={projectRole}>{work.fields.role.map((role, index) => (
        <Fragment key={index}>{role}{index < work.fields.role.length - 1 && <span css={workTechnologiesDivider}>/</span>}</Fragment>
      ))}</p>
    </div>
  );
};

export default WorkItem;
