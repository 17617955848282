import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span style={{ fontWeight: 'bold' }}>{children}</span>;
const Italic = ({ children }) => <em>{children}</em>;
const Underline = ({ children }) => <span style={{ textDecoration: 'underline' }}>{children}</span>;
const Text = ({ children }) => <p style={{ whiteSpace: 'pre-wrap' }}>{children}</p>;
const Hyperlink = ({ data, children }) => <a style={{ color: 'var(--link-color)', textDecoration: 'underline' }} href={data.uri} target='_blank' rel='noreferrer'>{children}</a>;
const Code = ({ children }) => <code>{children}</code>;
const Blockquote = ({ children }) => <blockquote>{children}</blockquote>;
const Ul = ({ children }) => <ul>{children}</ul>;
const Ol = ({ children }) => <ol>{children}</ol>;
const Li = ({ children }) => <li>{children}</li>;
const Hr = () => <hr />;
const Table = ({ children }) => <table><tbody>{children}</tbody></table>;
const TableRow = ({ children }) => <tr>{children}</tr>;
const TableCell = ({ children }) => <td>{children}</td>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HYPERLINK]: (node, children) => <Hyperlink data={node.data}>{children}</Hyperlink>,
    [BLOCKS.DOCUMENT]: (node, children) => <div>{children}</div>,
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.TABLE]: (node, children) => <Table>{children}</Table>,
    [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_CELL]: (node, children) => <TableCell>{children}</TableCell>,
    [BLOCKS.UL_LIST]: (node, children) => <Ul>{children}</Ul>,
    [BLOCKS.OL_LIST]: (node, children) => <Ol>{children}</Ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <Li>{children}</Li>,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [BLOCKS.CODE]: (node, children) => <Code>{children}</Code>,
    [INLINES.HYPERLINK]: (node, children) => <Hyperlink data={node.data}>{children}</Hyperlink>,
  },
};

const RichTextEditor = (content) => {
  return documentToReactComponents(content, options);
}

export default RichTextEditor;