import { css } from '@emotion/react';

export const animationWrap = css({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#1a1a1a',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999
})

export const lottieAnimation = () => css({
  position: 'relative',
  width: '500px',
  height: '500px',
  zIndex: 99
})

export const spreadCircle = () => css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  backgroundColor: '#4672C7',
  visibility: 'hidden'
})