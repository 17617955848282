/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRef, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../layout/Loader/loader/Loader';
import RichTextEditor from '../RichTextEditor';
import { 
  modalWrap,
  workTitle,
  workTopImage,
  workContents,
  workContentsWrap,
  workDescWrap,
  workDescLeft,
  workDescConcept,
  workDescRole,
  workDescTitle,
  linkBtnWrap,
  workDeskMultiplePcImgWrap,
  workDeskSinglePcImgWrap,
  workDeskPcImage,
  workDeskSpImgWrap,
  workDeskSpImage,
  closeBtn,
} from './Modal.style';

const Modal = ({ work, handleModalOpen, onRefUpdate }) => {
  const closeBtnRef = useRef(null)

  return (
    <div css={modalWrap} ref={(el) => onRefUpdate(el, work.sys.id)}>
      <div css={workContents}>
        {work.fields.topImages && <img css={workTopImage} src={work.fields.topImages.fields.file.url} alt="" />}
        <button 
          ref={closeBtnRef}
          css={closeBtn}
          onClick={() => handleModalOpen(work.sys.id)}
        >
          <div>
            <span />
          </div>
        </button>
        <div css={workContentsWrap}>
          <h1 css={workTitle}>{work.fields.projectName}</h1>
          {!work ? <Loader/> : (
            <Fragment>
              <div css={workDescWrap}>
                <div css={workDescLeft}>
                  <div css={workDescRole}>
                    <span css={workDescTitle}>ROLE</span>
                    {work.fields.role.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))}
                  </div>
                  <div>
                    <span css={workDescTitle}>USE TECHNOLOGIES</span>
                    {work.fields.useTechnologies.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))}
                  </div>
                </div>
                <div css={workDescConcept}>
                  <span css={workDescTitle}>CONCEPT</span>
                  {work.fields.projectDetails && RichTextEditor(work.fields.projectDetails)}
                </div>
              </div>
              <div css={linkBtnWrap}>
                {work.fields.appUrl && (
                  <a href={work.fields.appUrl} target="_blank" rel="noreferrer">
                    <span>VISIT WEBSITE</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </a>
                )}
                {work.fields.github && (
                  <a href={work.fields.github} target="_blank" rel="noreferrer">
                    <span>VISIT Github</span>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </a>
                )}
              </div>
              <div css={work.fields.pcImages.length > 1 ? workDeskMultiplePcImgWrap : workDeskSinglePcImgWrap}>
                {work.fields.pcImages.map((image, index) => (
                  <div css={workDeskPcImage}>
                    <img key={index} src={image.fields.file.url} alt=""/>
                  </div>
                ))}
              </div>
              <div css={workDeskSpImgWrap}>
                {work.fields.spImages?.map((image, index) => (
                  <div css={workDeskSpImage}>
                    <img key={index} src={image.fields.file.url} alt=""/>
                  </div>
                ))}
              </div>
            </Fragment>
          )}
          {/* <button onClick={() => handleModaliOpen(work.sys.id)}>Close</button> */}
        </div>
      </div>
    </div>
  )
}

export default Modal;