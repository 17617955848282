/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { scrolldown } from './ScrollDown.style';

const ScrollDown = () => {
  return(
    <div css={scrolldown}>
      <span>Scroll</span>
    </div>
  )
}

export default ScrollDown;