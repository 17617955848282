/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Footer from "./Footer/Footer";
import ThemeButton from "../ui/ThemeButton/ThemeButton";
import { main } from './Layout.style';

const Layout = ({ children }) => {
  return (
    <main css={main}>
      <ThemeButton />
      {children}
      <Footer />
    </main>
  )
}

export default Layout;