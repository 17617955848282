import { createSlice } from '@reduxjs/toolkit';

export const themeReducer = createSlice({
  name: 'theme',
  initialState: {
    theme: false,
  },
  reducers: {
    changeTheme: (state, action) => {
      state.theme = !state.theme;
    },
  },
});

export const { changeTheme } = themeReducer.actions;

export const selectTheme = (state) => state.theme;

export default themeReducer.reducer;
