import { css } from '@emotion/react';

export const navBar = css({
  width: '310px',
  zIndex: '999',
  position: 'fixed',
  right: '-120px',
  bottom: '50%',
  transform: 'translateY(50%) rotate(90deg)',
  '@media(max-width: 599px)': {
    right: '0',
    bottom: '0',
    width: '100%',
    transform: 'translateY(0%) rotate(0deg)',
    backgroundColor: 'var(--nav_background-color)',
    height: '6rem',
    boxShadow: '0 -2px 10px -1px rgb(0 0 0 / 38%)'

  },
});

export const navList = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '1.4rem',
  letterSpacing: '0.1rem',
  '@media(max-width: 599px)': {
    height: '100%',
    alignItems: 'center',
    padding: '0 1.8rem'
  },
  '& a': {
      color: 'var(--text-color)',
      '@media(max-width: 599px)': {
        backgroundColor: 'var(--nav-button-background-color)',
        width: '30%',
        padding: '0.8rem 0',
        textAlign: 'center',
        borderRadius: '100px',
        color: 'var(--nav-button-text-color)',
      }
    },
  '& li': {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '12px',
      height: '12px',
      left: '50%',
      bottom: '-25px',
      transform: 'translateX(-50%)',
      backgroundColor: 'var(--text-color)',
      borderRadius: '50%',
      '@media(max-width: 599px)': {
        display: 'none'
      }
    }
  }
})

export const line = {
  width: '267px',
  height: '1px',
  backgroundColor: 'var(--text-color)',
  display: 'block',
  position: 'absolute',
  bottom: '-20px',
  left: '10px',
  '@media(max-width: 599px)': {
    display: 'none'
  }
}