import { css } from '@emotion/react';

export const main = css({
  overflow: 'hidden',
  position: 'relative',
  color: 'var(--text-color)',
  backgroundColor: 'var(--background-color)',
  minHeight: '100vh',
  '@media(max-width: 599px)': {
    paddingBottom: '6rem'
  }
})