import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const navbarAnimation = (target, trigger, section, navList) => {
  const mm = gsap.matchMedia();
  mm.add(
    {
      isDesktop: '(min-width: 1024px)',
      isTablet: '(max-width: 1023px)',
      isMobile: '(max-width: 767px)',
    },(context) => {
      const { isMobile } = context.conditions;

      gsap.set(target, {
        autoAlpha: isMobile ? 1 : 0,
      });
      gsap.to(target, {
        autoAlpha: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: trigger,
          start: 'top-=280px top',
          toggleActions: 'play pause resume reverse',
        }
      })
    }
  )
}

export const navbarTextAnimation = (section, navList) => {
  ScrollTrigger.create({
    trigger: section[0],
    start: 'top center',
    onEnter: () => {
      gsap.set(navList[0], {
        color: '#41d6bb',
      })
    },
    onEnterBack: () => {
      gsap.set(navList[0], {
        color: '#41d6bb',
      })
      gsap.set(navList[1], {
        color: '#ffffff',
      })
    }
  })

  ScrollTrigger.create({
    trigger: section[1],
    start: 'top center',
    onEnter: () => {
      gsap.set(navList[0], {
        color: '#ffffff',
      })
      gsap.set(navList[1], {
        color: '#41d6bb',
      })
    },
    onEnterBack: () => {
      gsap.set(navList[2], {
        color: '#ffffff',
      })
      gsap.set(navList[1], {
        color: '#41d6bb',
      })
    }
  })

  ScrollTrigger.create({
    trigger: section[2],
    start: 'top center',
    onEnter: () => {
      gsap.set(navList[1], {
        color: '#ffffff',
      })
      gsap.set(navList[2], {
        color: '#41d6bb',
      })
    },
    onEnterBack: () => {
      gsap.set(navList[2], {
        color: '#ffffff',
      })
    }
  })
}