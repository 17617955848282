/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRef, useEffect } from 'react';
import { title } from './Title.style';
import { fadeInAnimation } from '../../../common/fadeInAnimation';

const Title = ({ titleName }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    fadeInAnimation(titleRef.current, 'scrollFadeIn');
  },[])

  return (
    <h1 css={title} ref={titleRef}>
      <span>{titleName}</span>
    </h1>
  );
};

export default Title;
