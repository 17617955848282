/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useCallback } from 'react';
import { useRef, useEffect, useState } from 'react';
import { fadeInAnimation } from '../../../common/fadeInAnimation';
import Title from '../../ui/Title/Title';
import WorkItem from '../../ui/WorkItem/WorkItem';
import Modal from '../../ui/Modal/Modal';
import Loading from '../../layout/Loader/loader/Loader';
import { modalDisplayAnimation, modalHiddenAnimation } from '../../ui/Modal/Modal.animation';
import { myWork, myWorkItems } from './WorkList.style';

const WorkList = ({ works, isLoadingWorks, isFetchingWorks }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const workItemRef = useRef(null);
  const workListRef = useRef([]);
  
  const onRefUpdate = useCallback((el, id) => {
    workListRef.current[id] = el;
  },[])

  const handleModalOpen = (id) => {
    setModalOpen(!modalOpen);
    if(modalOpen) {
      document.body.style.overflow = 'auto';
      modalHiddenAnimation(workListRef.current[id])
    } else {
      document.body.style.overflow = 'hidden';
      modalDisplayAnimation(workListRef.current[id])
    }
  }

  const handleLinkClick = (e, link) => {
    e.stopPropagation();
    window.open(link, '_blank', 'noreferrer');
  }
  
  useEffect(() => {
      if(!isLoadingWorks && !isFetchingWorks) {
        fadeInAnimation(workItemRef.current.childNodes, 'scrollEachItemFadeIn')
      }
  }, [isLoadingWorks, isFetchingWorks]);

  return (
    <section css={myWork} id="my-work">
      <Title titleName="MY WORK" />
      {isLoadingWorks || isFetchingWorks ? <Loading /> : (
        <Fragment>
          <div css={myWorkItems} ref={workItemRef}>
            {works.map((work) => (
              <WorkItem
                key={work.sys.id}
                work={work}
                handleModalOpen={handleModalOpen}
                handleLinkClick={handleLinkClick}
              />
            ))}
          </div>
          {works.map((work) => (
            <Modal
              key={work.sys.id}
              work={work} 
              onRefUpdate={onRefUpdate} 
              handleModalOpen={handleModalOpen} 
            />
          ))}
        </Fragment>
      )}
    </section>
  );
};

export default WorkList;
