import emotionReset from 'emotion-reset';
import { css } from '@emotion/react';

export const styles = css`
${emotionReset}
*, *::after, *::before {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 16px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Krungthep', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
`