import { css } from '@emotion/react';

export const scrolldown = css({
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  '& span': {
    position: 'absolute',
    left: '10px',
    bottom: '10px',
    color: '#ffffff',
    fontSize: '1rem',
    letterSpacing: '0.05em',
    writingMode: 'vertical-rl'
  },
  '&::before': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: '-4px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: '#ffffff',
    animation:
      'circlemove 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite'
  },
  '&::after': {
    content:"''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '1px',
    height: '50px',
    background: '#ffffff',
    opacity: 0.7
  }
})