import { css } from '@emotion/react';

export const themeSwitch = css({
  position: 'fixed',
  right: '2rem',
  top: '2rem',
  background: 'transparent',
  order: 'none',
  outline: 'none',
  padding: 0,
  appearance: 'none',
  border: 0,
  cursor: 'pointer',
  width: '100px',
  zIndex: '999',
  '@media(max-width: 599px)': {
    right: '1.2rem',
    top: '1.2rem',
    width: '90px',
  }
});
