/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme, selectTheme } from '../../../redux/themeSlice';
import Lottie from 'react-lottie-player';
import toggleSwitch from '../../../assets/lottieAnimation/toggle-switch.json';
import { themeSwitch } from './ThemeButton.style';

const ThemeButton = () => {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const segmentsToPlay = theme ? [91, 179] : [0, 90];
  const segmentsToGoTo = theme ? 0 : 91;

  const handleClick = () => {
    dispatch(changeTheme());
    if (!theme) {
      document.documentElement.removeAttribute('data-theme');
    } else document.documentElement.setAttribute('data-theme', 'light');
  };

  useEffect(() => {
    if (!theme) {
      document.documentElement.removeAttribute('data-theme');
    } else document.documentElement.setAttribute('data-theme', 'light');
  });

  return (
    <button css={themeSwitch} onClick={() => handleClick()}>
      <Lottie
        speed={5}
        play={true}
        loop={false}
        segments={segmentsToPlay}
        goTo={segmentsToGoTo}
        animationData={toggleSwitch}
        style={{ width: '100%', height: '100%' }}
      />
    </button>
  );
};

export default ThemeButton;
