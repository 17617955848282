/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRef,useEffect, Fragment } from 'react';
import Lottie from 'react-lottie-player';
import Wave from '../../../../assets/lottieAnimation/pageOpenLoading.json';
import { animationWrap, spreadCircle, lottieAnimation } from './TopLoader.style';
import { circleSpreadAnimation } from './TopLoader.animation';

const TopLoader = ({ pageLoad }) => {
  const circleRef = useRef(null);
  useEffect(() => {
    pageLoad && circleSpreadAnimation(circleRef.current);
  },[pageLoad])
  return (
    <Fragment>
      <div css={animationWrap} ref={circleRef}>
        <div css={lottieAnimation}>
          <Lottie
            speed={1}
            play={pageLoad ? true : false}
            loop={false}
            animationData={Wave}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div ref={circleRef} css={spreadCircle}></div>
      </div>
    </Fragment>
  )
}

export default TopLoader;