/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { motion } from 'framer-motion/dist/framer-motion'
import { useState,useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme, selectTheme } from '../../../redux/themeSlice';
import { followingAnimation, nameVariants, occupationVariants } from './Head.animation';
import Wave from '../../ui/Wave/Wave';
import ScrollDown from '../../ui/scrollDown/ScrollDown';
import star from '../../../assets/images/star.svg';
import bird from '../../../assets/images/bird.svg';
import tokyo from '../../../assets/images/tokyo_silhouette2.svg';
import moon from '../../../assets/images/full_moon.svg';
import sun from '../../../assets/images/sun.png';
import {
  head,
  tokyoImg,
  nameWrap,
  nameWrapitem,
  imgWrap,
  position,
  name1,
  name2,
  name3,
  name4,
  name5,
  name6,
  name7,
  name8,
  titleImg,
  starImg,
  birdImg,
} from './Head.style';

const Head = () => {
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [clicked3, setClicked3] = useState(false);
  const [clicked4, setClicked4] = useState(false);
  const [clicked5, setClicked5] = useState(false);
  const [clicked6, setClicked6] = useState(false);
  const [clicked7, setClicked7] = useState(false);
  const [clicked8, setClicked8] = useState(false);

  const handleClick = (location) => {
    switch (location) {
      case 1:
        return setClicked1(!clicked1);
      case 2:
        return setClicked2(!clicked2);
      case 3:
        return setClicked3(!clicked3);
      case 4:
        return setClicked4(!clicked4);
      case 5:
        return setClicked5(!clicked5);
      case 6:
        return setClicked6(!clicked6);
      case 7:
        return setClicked7(!clicked7);
      case 8:
        return setClicked8(!clicked8);
      default:
        return false;
    }
  };

  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const themeHandleClick = () => {
    dispatch(changeTheme());
    if (!theme) {
      document.documentElement.removeAttribute('data-theme');
    } else document.documentElement.setAttribute('data-theme', 'light');
  };
  
  const nameRef = useRef(null);
  const headRef = useRef(null);
  // const waveRef = useRef(null);

  useEffect(() => {
    followingAnimation(nameRef.current, headRef.current, 'name')
    // followingAnimation(waveRef.current, headRef.current, 'wave')
  },[])

  return (
    <div css={head} ref={headRef}>
      {theme ? (
        <img css={birdImg} src={bird} alt="bird" />
      ) : (
        <img css={starImg} src={star} alt="star" />
      )}
      <div css={nameWrap} ref={nameRef}>
        <motion.div 
          css={nameWrapitem} 
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            css={name1(clicked1)} 
            onClick={(e) => handleClick(1)}
            variants={nameVariants}
            whileHover={{ y: -10 }}
          >
            M
          </motion.h1>
          <motion.h1 
            css={name2(clicked2)} 
            onClick={(e) => handleClick(2)}
            variants={nameVariants}
            whileHover={{ y: -10 }}
          >
            A
          </motion.h1>
          <motion.h1 
            css={name3(clicked3)} 
            onClick={(e) => handleClick(3)}
            variants={nameVariants}
            whileHover={{ y: -10 }}
          >
            I
          </motion.h1>
        </motion.div>
        <div css={nameWrapitem}>
          <motion.h1 
            css={name4(clicked4)} 
            onClick={(e) => handleClick(4)}
            variants={nameVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ y: -10 }}
          >
            G
          </motion.h1>
          <h1 css={titleImg(theme)} onClick={() => themeHandleClick()}>
            {!theme ? (
              <img src={moon} alt="moon" />
            ) : (
              <img src={sun} alt="sun" />
            )}
          </h1>
          <motion.h1 
            css={name5(clicked5)} 
            onClick={(e) => handleClick(5)}
            variants={nameVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ y: -10 }}
          >
            H
          </motion.h1>
          <motion.h1 
            css={name6(clicked6)} 
            onClick={(e) => handleClick(6)}
            variants={nameVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ y: -10 }}
          >
            A
          </motion.h1>
          <motion.h1 
            css={name7(clicked7)} 
            onClick={(e) => handleClick(7)}
            variants={nameVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ y: -10 }}
          >
            R
          </motion.h1>
          <motion.h1 
            css={name8(clicked8)} 
            onClick={(e) => handleClick(8)}
            variants={nameVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ y: -10 }}
          >
            A
          </motion.h1>
        </div>
      </div>
      <div css={imgWrap}>
        <img src={tokyo} alt="tokyo" />
        <img src={tokyo} alt="tokyo" css={tokyoImg} />
      </div>
      <motion.p 
        css={position}
        variants={occupationVariants}
        initial="hidden"
        animate="visible"
      >
        Front end Developer
      </motion.p>
      <Wave />
      <ScrollDown />
    </div>
  );
};

export default Head;
