import { css } from '@emotion/react';

export const workItemWrap = css({
  width: '100%',
});

export const workItemContent = css({
  position: 'relative',
  '&::before': {
    content: "''",
    display: 'block',
    paddingTop: '56.25%',
  },
});

export const imgWrap = css({
  position: 'absolute',
  width: '100%',
  height: 'auto',
  aspectRatio: '16 / 9',
  overflow: 'hidden',
  top: 0,
  left: 0,
  '& img': {
    width: '100%',
  }
});

export const textWrap = css({
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  color: 'var(--secondary-color)',
  fontWeight: 'bold',
  fontSize: '2rem',
  transition: '0.4s',
  cursor: 'pointer',
  '@media(max-width: 599px)': {
    fontSize: '2.2rem',
  },
  '& p': {
    opacity: 0,
    transition: 'ease-in-out 0.4s',
    color: '#fff'
  },
  '& button': {
    opacity: 0,
    transition: 'ease-in-out 0.4s',
    color: '#fff',
    width: '45px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #ffffff',
    borderRadius: '100px',
    background: 'transparent',
    cursor: 'pointer',
    outline: 'none',
    padding: 0,
    appearance: 'none',
    fontSize: '1.8rem',
  },
  '&::before': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    content: '""',
    width: '157%',
    height: '157%',
    backgroundColor: 'rgba(65, 214, 187, 0.8)',
    transformOrigin: 'right top',
    transform: 'skewX(-30deg) scale(0, 1)',
    transition: 'transform 0.6s',
  },
  '&:hover': {
    color: '#fff',
    '&::before': {
      transformOrigin: 'left top',
      transform: 'skewX(-30deg) scale(1.6, 1)',
      backgroundColor: 'rgba(65, 214, 187, 0.8)',
    },
    '& p': {
      opacity: 1
    },
    '& button': {
      opacity: 1,
    }
  },
});

export const linkWrap = css({
  position: 'absolute',
  top: '15px',
  right: '15px',
  display: 'flex',
  gap: '12px',
  '& button': {
    '&:hover': {
      backgroundColor: '#ffffff',
      color: 'var(--primary-color)',
    }
  },
})

export const workTechnologies = css({
  fontSize: '1.6rem',
  paddingTop: '10px',
  fontWeight: '600',
  lineHeight: '1.3',
  overflowWrap: 'break-word',
  '@media(max-width: 1024px)': {
    fontSize: '1.4rem',
  },
});

export const workName = css({
  fontSize: '2.3rem',
  lineHeight: '1.3',
  '@media(max-width: 1024px)': {
    fontSize: '1.8rem',
  },
})

export const workTechnologiesDivider = css({
  padding: '0 0.5rem'
})

export const projectName = css({
  paddingTop: '1.8rem',
  fontSize: '2rem',

})

export const projectRole = css({
  paddingTop: '1.2rem',
  fontSize: '1.4rem',
})

