import { css } from '@emotion/react';

export const myWork = css({
  margin: '6rem 0 18rem 0',
  '@media(max-width: 1024px)': {
    margin: '6rem 0 13rem 0',
  },
  '@media(max-width: 599px)': {
    margin: '6rem 0 14rem 0',
  },
});

export const myWorkItems = css({
  marginTop: '7rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns for desktop
  gap: '5rem', // gap between grid items

  '@media(max-width: 1024px)': {
    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns for tablets
  },
  '@media(max-width: 767px)': {
    gridTemplateColumns: 'repeat(1, 1fr)', // 1 column for mobile
  }
});