import { useQuery } from 'react-query';
import { client } from '../client';

export const useQuerySkills = () => {
  const getSkills = async () => {
    const data = await client.getEntries({
      content_type: 'skills',
    });
    return data
  }

  return useQuery({
    queryKey: 'skill',
    queryFn: getSkills,
    cacheTime: 10000,
    staleTime: 0,
  })
}