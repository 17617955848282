/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useRef, useEffect } from 'react';
import Title from '../../ui/Title/Title';
import Skill from '../../ui/Skill/Skill';
import { skillsWrap, description, content, contentLeft ,contentRight, contactInfo, descriptionName, iconWrap, contactInfoContent, contactInfoItem } from './About.style';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import Loading from '../../layout/Loader/loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fadeInAnimation } from '../../../common/fadeInAnimation';

const About = ({
  skills,
  isLoadingSkills,
  isFetchingSkills,
  profile,
  isLoadingProfile,
  isFetchingProfile
}) => {
  const skillItemRef = useRef(null);
  
  useEffect(() => {
    if(!isLoadingSkills && !isFetchingSkills && !isLoadingProfile && !isFetchingProfile) {
        fadeInAnimation(skillItemRef.current.childNodes, 'scrollEachItemFadeIn')
      }
  }, [isLoadingSkills, isFetchingSkills, isLoadingProfile, isFetchingProfile]);

  return (
    <section id="about-me">
      <Title titleName="ABOUT ME" />
      <div css={content}>
        {isLoadingSkills || 
        isFetchingSkills ||
        isLoadingProfile ||  
        isFetchingProfile ? 
          <Loading/>
        : (
          <Fragment>
            <div css={contentLeft}>
              <div css={description}>
                <h2 css={descriptionName}>{profile[0].fields.name}</h2>
                <br />
                {profile[0].fields.description.content.map((item, index) => (
                  item.nodeType === 'paragraph' && 
                    (item.content[0].value !== '' ? 
                      <p key={index}>{item.content[0].value}</p> : 
                      <br key={index} />
                    )
                ))}
              </div>
              <div css={skillsWrap} ref={skillItemRef}>
                {skills.map((skill, index) => (
                  <Skill key={index} skill={skill} />
                ))}
              </div>
            </div>
            <div css={contentRight}>
              <h2>GET IN TOUCH</h2>
              <div css={contactInfo}>
                <div css={contactInfoContent}>
                  <a
                    css={contactInfoItem}
                    href="mailto:maigohara3@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <p>maigohara3@gmail.com</p>
                  </a>
                  <div css={contactInfoItem}>
                    <span>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <p>+1 (604) 690-0583</p>
                </div>
                </div>
                <div css={iconWrap}>
                  <a
                    href="https://www.linkedin.com/feed/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <FontAwesomeIcon icon={faLinkedin} />
                    </span>
                  </a>
                  <a
                    href="https://github.com/mai5hara"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <FontAwesomeIcon icon={faGithub} />
                    </span>
                  </a>
                  <a
                    href="https://www.instagram.com/mai5hara"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <FontAwesomeIcon icon={faInstagram} />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default About;
